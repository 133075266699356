import { getUrlVars, toEstimate, shippingDateSelectionReplace} from "../Shared/SiteCommon";
//import { cookie } from "../Shared/Cookie";

namespace PaperStrawSiteCommon {
    const siteId = 9;
    jQuery(function () {
        const vars = getUrlVars();
        
        if (vars["debug"] == 'true') {

        }

        if (location.href.indexOf('/contact') != -1) {
            location.href = 'https://www.paper-straw.jp/page/9';
        }

        var alist = jQuery("a[href^='https://www.paper-straw.jp/contact']");
        alist.attr("href", '/page/9?from=top');


        setupEstimateButton();


        var offsetY = -10;
        // スクロールにかかる時間
        var time = 500;

        // ページ内リンクのみを取得
        jQuery('a[href^="#"]').click(function () {
            // 移動先となる要素を取得
            var target = jQuery(this.hash);
            if (!target.length) return;
            // 移動先となる値
            var targetY = target.offset().top + offsetY;
            // スクロールアニメーション
            jQuery('html,body').animate({ scrollTop: targetY }, time, 'swing');
            // ハッシュ書き換えとく
            window.history.pushState(null, null, this.hash);
            // デフォルトの処理はキャンセル
            return false;
        });

        shippingDateSelectionReplace();
        //removeCart1Text();
    });

    function cookieTest() {
    }


    function setupEstimateButton() {
        //カートの最初のページのみ発動
        if (document.getElementById("shoppingcart_page_step0") != null) {
            const form = jQuery("#register");
            const formBtns = form.find(".form_btn");
            const lastElem = formBtns.last();
            lastElem.append(
                `<span class="global_btn registerinput_btn">
    <button type="button" id="estimate" class="registerinput btn_size_xxlarge btn_color_emphasis">
        <span class="button_head_space"></span>
        <span class="button_text">見積書発行</span>
        <span class="button_end_space"></span>
    </button>
</span>`);
            const estBtn = document.getElementById("estimate");
            estBtn.onclick = <any>((a, b) => {
                toEstimate(siteId);
            });
        }
    }

    //function toEstimate() {
    //    const formInnnerHtml = jQuery("#register").html();
    //    const results = new Array();
    //    let idx = 0;
    //    jQuery(".cart_data_box")
    //        .each(function () {
    //            results.push(parseProduct(idx, this));
    //            idx++;
    //        });

    //    const query = results.join("&");
    //    const url = "https://www.workup-system.com/Estimate/Print/?siteId=9&" + query;
    //    window.open(url);
    //}

 

    function removeCart1Text() {
        if (document.location.href.indexOf("/cart/1") == -1)
            return;
        var parentDiv = document.getElementById("shippingdatetime_box_1");
        if (parentDiv == null)
            return;

        if (parentDiv.childNodes.length < 2)
            return;

        if (parentDiv.childNodes[2].textContent.indexOf("※お届け日はご指定いただけません") == -1)
            return;
        parentDiv.childNodes[2].remove();
    }

} 